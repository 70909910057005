<template>
  <div class="">
    <div class="df bg-white head">
      <div class="dfc mr50">
        <el-select v-model="minFormdata.videoStatus" placeholder="请选择视频合成状态">
          <el-option v-for="item in $store.state.statusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="dfc mr50">
        <el-input v-model="minFormdata.videoName" placeholder="请输入视频名称"></el-input>
      </div>
      <div class="df">
        <el-button plain type="primary" @click="getlive">搜索</el-button>
        <el-button plain @click="clear">清理</el-button>
        <el-button plain type="primary" @click="getlive">刷新</el-button>
        <el-button v-show="!choose" plain type="warning" @click="choose = true">选择下载</el-button>
        <el-button v-show="choose" plain type="warning" @click="choose = false">取消选择</el-button>
        <el-button v-show="choose" plain type="success" @click="download">下载</el-button>
      </div>
    </div>
    <div class="pl20">
      <el-checkbox-group v-model="checkList">
        <div class="df f-w box" v-if="videoList.length > 0">
          <div v-for="(item, idx) in videoList" :key="idx" @click="getItem(item)" class="imgBox">
            <img :src="item.video_image" object-fit="cover" alt="" />
            <div class="desc textOver">
              {{ item.title }}
              <div class="time">{{ item.create_time }}</div>
            </div>
            <div v-show="!choose" class="tools" style="color: #ff5733" @click.stop="delItem(item)">
              <i class="el-icon-delete"></i>
            </div>
            <div v-show="choose" class="tools" style="color: #ff5733" @click.stop>
              <el-checkbox v-show="choose" :value="item.id" :label="item.id"></el-checkbox>
            </div>
            <div class="status">
              <div class="sta" :style="item.statusa | getColor">
                {{ item.statusa | getStatus }}
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <myempty text="视频"></myempty>
        </div>
      </el-checkbox-group>
    </div>

    <el-dialog :title="title" :visible.sync="dialogVisible" width="38%">
      <el-input v-model="ipt" placeholder="请输入直播方案名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            ipt = '';
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="add">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="activeInfo.title" :visible.sync="showVideo" width="28%" :destroy-on-close="true">
      <video ref="v1" :src="activeInfo.url" class="vd" controls></video>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showVideo = false">取 消</el-button>
        <el-button type="primary" @click="showVideo = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getlive, dellive, editlive, createlive, get_live_video } from "@/api/live.js";
import { getVideo, del_video } from "@/api/video.js";
import { downloadVideo } from "@/utils/tools";
import myempty from "@/components/myempty.vue";
var timer;
export default {
  components: {
    myempty,
  },
  filters: {
    getStatus: (val) => {
      if (!val) return "";
      return val == "1" ? "合成中" : val == "3" ? "已完成" : "合成失败";
    },
    getColor: (val) => {
      if (!val) return "";
      return val == "1" ? "color:#0052d9;" : val == "3" ? "color:#43CF7C;" : "color:#FF5733;";
    },
  },
  data() {
    return {
      visible: false,
      dialogVisible: false,
      innerVisible: false,
      choose: false,
      showVideo: false,
      minFormdata: {
        videoStatus: "",
        videoName: "",
      },
      ipt: "",
      title: "添加直播方案",
      player: null, // 用来存储当前 video
      inputLive: "",
      liveAddr: "",
      roomId: "", //直播房间号
      activeInfo: {},
      activeI: {},
      chooseRoom: "",
      liveInfo: {
        livelist: [],
        keywordList: [],
      },
      downloadList: [],
      value: "",
      isPlay: false,
      videoList: [],
      checkList: [],
    };
  },
  mounted() {
    this.getlive();
    timer = setInterval(() => {
      this.getlive();
    }, 60000);
  },
  destroyed() {
    clearTimeout(timer);
  },
  methods: {
    getItem(item) {
      if (item.statusa == "3") {
        this.activeInfo = item;
        this.showVideo = true;
      }
    },
    clear() {
      this.minFormdata = {
        videoStatus: "",
        videoName: "",
      };
      this.getlive();
    },
    download() {
      if (this.checkList.length > 0) {
        this.checkList.map((i) => {
          let obj = this.videoList.find((j) => j.id === i);
          console.log(111, obj);
          if (obj.url) {
            downloadVideo(obj.url);
          }
        });
      } else {
        this.$message.Warning("请选择文件下载！");
      }
    },
    add() {
      if (!this.ipt) {
        this.$message.error("请输入直播方案名称");
        return;
      }

      switch (this.type) {
        case "rename":
          editlive({
            live_id: this.activeI.id,
            title: this.ipt,
          }).then((res) => {
            if (res.code == "200") {
              this.dialogVisible = false;
              this.$emit("add", this.activeI.id);
            } else {
              this.$message.error(res.msg);
            }
          });
          break;
        default:
          createlive({
            title: this.ipt,
          }).then((res) => {
            if (res.code == "200") {
              this.dialogVisible = false;
              this.$emit("add", res.data.live_id);
            } else {
              this.$message.error(res.msg);
            }
          });
          break;
      }
      this.ipt = "";
    },
    getlive() {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getVideo({
        page: 1,
        item: 1000,
        type_s: 1,
        statusa: this.minFormdata.videoStatus || "",
        so: this.minFormdata.videoName || "",
      }).then((res) => {
        setTimeout(() => {
          loading.close();
          if (res.code == "200") {
            this.videoList = res.data.list;
          } else {
            this.$message.error(res.msg);
          }
        }, 200);
      });
    },
    delItem(i) {
      this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((_) => {
          del_video({
            video_id: i.id,
          }).then((res) => {
            if (res.code == "200") {
              this.$message.success(res.msg);
              this.getlive();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

/deep/.el-textarea__inner {
  background: #f1f4f9 !important;
}
/deep/.el-checkbox__inner {
  width: 20px;
  height: 20px;
  border: 1px solid #015ce6 !important;
  margin-left: 3px;
}
/deep/.el-checkbox__inner::after {
  height: 12px;
  left: 7px;
}
/deep/.el-checkbox-group {
  font-size: 15px !important;
}
/deep/.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: left;
  font-weight: bold;
}

/deep/.el-dialog {
  border-radius: 8px;
}

.title {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}

.info {
  padding-top: 5px;
  padding-bottom: 5px;

  /deep/.el-pagination {
    padding: 5px 5px 0 !important;
  }
}

.status {
  position: relative;
  top: -401px;
  left: -3px;
  width: 70px;
  height: 70px;
  opacity: 0.9;
  background: url(~@/assets/img/status.png) no-repeat;
  background-size: contain;
  .sta {
    transform: rotate(315deg);
    font-size: 14px;
    text-align: left;
    padding-top: 5px;
    margin-left: -3px;
    font-weight: 600;
  }
}
</style>
